<script setup lang="ts">
import { ref } from 'vue';
import type { AdditionalUrlsData } from '../../page-management-tool/pmt-interfaces.js';

const emit = defineEmits<{
    (e: 'additionalUrlChange', data:AdditionalUrlsData ): void
}>();
const props = defineProps<{
    urlModel: AdditionalUrlsData;
    index: number;
}>();
type ActionType = 'remove' | 'edit' | 'default';
let actionType = ref<ActionType>('default');
const ActionButtonLabelMap: Record<ActionType, string> = {
    'remove': 'DELETE',
    'edit': 'SUBMIT',
    'default': 'default'
}
function HandleAdditionalUrlChange() {
    switch (actionType.value) {
        case 'remove':
            props.urlModel.AdditionalUrl = '';
            emit('additionalUrlChange', props.urlModel);
            break;
        case 'edit':
            emit('additionalUrlChange', props.urlModel)
            break;
        default:
            break;
    }
    actionType.value = 'default';
}
function FormatUrl(url:AdditionalUrlsData){
    const prefixMap = {
            'en': '/',
            'en-AU': '/au/',
            'ja-JP': '/jp/',
            'en-GB': '/uk/',
            'fr-FR': '/fr/',
            'ko-KR' : '/kr/',
            'de-DE': '/de/',
            'zh-CN': '/cn/',
        };
    return prefixMap[url.Lang] + url.AdditionalUrl.replace('~/', '');
}
</script>
<template>
    <div class="row-entry">
        <a :href="FormatUrl(urlModel)" target="_blank" class="url-text"
            v-if="actionType == 'default' || actionType == 'remove'">
            {{ urlModel.AdditionalUrl }}
        </a>
        <input v-if="actionType == 'edit'" type="text" v-model="urlModel.AdditionalUrl">
    </div>
    <div class="svg-container" v-if="actionType == 'default'">
        <svg class="edit-svg" width="18" height="16" viewBox="0 0 18 16" fill="none" v-on:click="actionType = 'edit'"
            xmlns="http://www.w3.org/2000/svg">
            <path
                d="M12.5625 10.7578C12.5 10.8203 12.5 10.8828 12.5 10.9453V14.4766H1.5V3.47656H9.03125C9.09375 3.47656 9.15625 3.47656 9.21875 3.41406L10.2188 2.41406C10.375 2.25781 10.25 1.97656 10.0312 1.97656H1.5C0.65625 1.97656 0 2.66406 0 3.47656V14.4766C0 15.3203 0.65625 15.9766 1.5 15.9766H12.5C13.3125 15.9766 14 15.3203 14 14.4766V9.94531C14 9.72656 13.7188 9.60156 13.5625 9.75781L12.5625 10.7578ZM17.4375 4.47656C18.1562 3.75781 18.1562 2.60156 17.4375 1.88281L16.0938 0.539062C15.375 -0.179688 14.2188 -0.179688 13.5 0.539062L5.3125 8.72656L5 11.5703C4.90625 12.3828 5.59375 13.0703 6.40625 12.9766L9.25 12.6641L17.4375 4.47656ZM14.375 5.41406L8.5625 11.2266L6.5 11.4766L6.75 9.41406L12.5625 3.60156L14.375 5.41406ZM16.375 2.94531C16.5312 3.07031 16.5312 3.25781 16.4062 3.41406L15.4375 4.38281L13.625 2.53906L14.5625 1.60156C14.6875 1.44531 14.9062 1.44531 15.0312 1.60156L16.375 2.94531Z"
                fill="#C53494"></path>
        </svg>
        <svg class="delete-svg" width="18" height="16" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"
            v-on:click="actionType = 'remove'">
            <path fill="#4e4e50"
                d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM175 175c9.4-9.4 24.6-9.4 33.9 0l47 47 47-47c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9l-47 47 47 47c9.4 9.4 9.4 24.6 0 33.9s-24.6 9.4-33.9 0l-47-47-47 47c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9l47-47-47-47c-9.4-9.4-9.4-24.6 0-33.9z" />
        </svg>
    </div>
    <div class="button-container" v-if="actionType != 'default'">
        <div :class="`${ActionButtonLabelMap[actionType]}-button`" v-on:click="HandleAdditionalUrlChange">{{
            ActionButtonLabelMap[actionType] }}</div>
        <div v-on:click="actionType = 'default'">Cancel</div>
    </div>
</template>