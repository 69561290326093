<script setup lang="ts">
import type { AdditionalUrlsData as Url, CustomRequest } from "../../page-management-tool/pmt-interfaces.js";
import UrlEntry from "./url-entry.vue";
import DeleteAndRedirect from "./delete-redirect.vue";
import { ref } from "vue";
import { usePageNodeStore } from "../../page-management-tool/page-node-store.js";
import { PageNodeModel } from "../../page-management-tool/PageNode.js";
import { watch } from "vue";
const props = defineProps<{
    data: [PageNodeModel, Url[]];
    label: string;
    componentType: string;
}>();
const PageNodeStore = usePageNodeStore();
let AllAdditionalUrls = ref<Url[]>(props.data[1]);
let pageNodeData = ref<PageNodeModel>(props.data[0]);
const newAddUrl = ref<string>('');
let showModal = ref<boolean>(false);
watch(() => props.data[1], (newValue) => {
    AllAdditionalUrls.value = newValue;
});
function HandleRedirectActions(data: Url) {
    let urlValuesToSend: Url[] = AllAdditionalUrls.value;
    if (!data.AdditionalUrl) {
        urlValuesToSend = AllAdditionalUrls.value.filter(url => url.Id != data.Id);
    } else {
        urlValuesToSend.find(url => url.Id == data.Id)!.AdditionalUrl = data.AdditionalUrl;
    }
    const customRequest: CustomRequest = {
        PageNodeId: props.data[0].getNodeId,
        Field: 'Urls',
        ListValue: urlValuesToSend.map(url => url.AdditionalUrl),
        TypeOfRequest: 'UpdateAdditionalUrls',
        LanguageCode: data.Lang,
    }
    PageNodeStore.saveAdditionalUrlData(customRequest).then(data => AllAdditionalUrls.value = data[1]);
}
function AddUrl() {
    if (!newAddUrl.value.startsWith('~/')) {
        window.alert('The url you are trying to add MUST start with ~/');
        return;
    }
    if(!newAddUrl.value.endsWith('/'))
    {
        window.alert('The url you are trying to add MUST end with /');
        return;
    }
    const tempUrlModel: Url = { AdditionalUrl: newAddUrl.value, Lang: props.data[0].langSelected, Id: '', RedirectToDefualt: true, }
    AllAdditionalUrls.value.unshift(tempUrlModel);
    HandleRedirectActions(tempUrlModel);
    newAddUrl.value = '';
}
</script>
<template>
    <div class="content-data">
        <template v-for="(entry, index) in AllAdditionalUrls" :key="entry.Id">
            <div class="url-row" v-if="entry.AdditionalUrl">
                <UrlEntry :urlModel="entry" :index="index" @additional-url-change="HandleRedirectActions"></UrlEntry>
            </div>
        </template>
    </div>
    <div class="add-container">
        <div class="input-label">Add a New Additional Url: </div>
        <input class="add-input" type="text" placeholder="Add a new redirect..." v-model="newAddUrl">
        <div class="button" v-on:click="AddUrl">Submit</div>
    </div>
    <div class="del-redirect" v-on:click="showModal = true">Delete Page and Redirect</div>
    <DeleteAndRedirect v-if="showModal" @close-modal="showModal = !showModal" :page-node-id="pageNodeData.getNodeId"
        :lang-selected="pageNodeData.langSelected" :page-url="pageNodeData.getUrl" :redirects="AllAdditionalUrls" />
</template>